<template>
  <main>
    <sqr-hero
      title="signup_title"
      subtitle="signup_subtitle"
      color="primary"
      size="small"
      is-bold
    />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="card">
              <div class="card-content">
                  <div class="field">
                    <Logo :height="32"/>
                  </div>
                  <error-notifications :errors="errors"/>
                <div class="field">
                  <sqr-input-email
                    name="email"
                    label="signup_email"
                    v-model="email"
                    placeholder="signup_email"
                    size="medium"
                    :invalid="$v.email.$error"
                    @keyup.enter="signUp()"
                  />
                  <div v-if="$v.email.$error && !$v.email.required" class="help is-danger">{{$t('signup_email_required')}}</div>
                  <div v-if="$v.email.$error && !$v.email.email" class="help is-danger">{{$t('signup_email_invalid')}}</div>
                </div>
                <div class="field">
                  <sqr-input-password
                    name="password"
                    label="signup_password"
                    v-model="password"
                    placeholder="signup_password"
                    size="medium"
                    :invalid="$v.password.$error"
                    @keyup.enter="signUp()"
                  />
                  <div v-if="$v.password.$error && !$v.password.required" class="help is-danger">{{$t('signup_password_required')}}</div>
                  <div v-if="$v.password.$error && !$v.password.minLength" class="help is-danger">{{$t('signup_password_length_min')}}</div>
                </div>
                <div class="field">
                  <sqr-input-text
                    name="fname"
                    label="signup_givenname"
                    v-model="givenName"
                    placeholder="signup_givenname"
                    size="medium"
                    :invalid="$v.givenName.$error"
                    @keyup.enter="signUp()"
                    />
                  <div v-if="$v.givenName.$error" class="help is-danger">{{$t('signup_givenname_required')}}</div>
                </div>
                <div class="field">
                  <sqr-input-text
                    name="lname"
                    label="signup_familyname"
                    v-model="familyName"
                    placeholder="signup_familyname"
                    size="medium"
                    :invalid="$v.familyName.$error"
                    @keyup.enter="signUp()"
                  />
                  <div v-if="$v.familyName.$error" class="help is-danger">{{$t('signup_familyname_required')}}</div>
                </div>
                <div class="field">
                  <sqr-input-text
                    name="company"
                    label="signup_company"
                    v-model="company"
                    placeholder="signup_company"
                    size="medium"
                    :invalid="$v.company.$error"
                    @keyup.enter="signUp()"
                  />
                  <div v-if="$v.company.$error" class="help is-danger">{{$t('signup_company_required')}}</div>
                </div>
                <div class="field">
                  <sqr-router-link label="signup_already" to-name="sign-in" is-link/>
                </div>
                <error-notifications class="field" :errors="errors"/>
                <div class="buttons is-right">
                  <sqr-button
                    icon="sign-in"
                    label="signup_confirm"
                    color="primary"
                    size="medium"
                    @click="signUp()"
                    is-fullwidth
                    :is-loading="loading"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  import {validationMixin} from 'vuelidate';
  import {required, email, minLength} from 'vuelidate/lib/validators';

  import SqrInputEmail from '@/sqrd/SqrInputEmail';
  import SqrInputPassword from '@/sqrd/SqrInputPassword';
  import SqrRouterLink from '@/sqrd/SqrRouterLink';
  import SqrButton from '@/sqrd/SqrButton';
  import SqrInputText from '@/sqrd/SqrInputText';

  import ErrorNotifications from './ErrorNotifications';

  export default {
    name: 'SignUp',
    mixins: [validationMixin],
    components: {SqrInputText, SqrInputEmail, SqrInputPassword, SqrRouterLink, ErrorNotifications, SqrButton},
    computed: {
      ...mapGetters('auth', ['loading', 'authenticated', 'errors'])
    },
    data () {
      return {
        status: 'boarding',
        email: '',
        givenName: '',
        familyName: '',
        company: '',
        password: ''
      };
    },
    validations: {
      email: {required, email},
      password: {required, minLength:minLength(6)},
      givenName: {required},
      familyName: {required},
      company: {required}
    },
    watch: {
      authenticated (n) {
        if (n) {
          this.go();
        }
      }
    },
    mounted () {
      this.reset();
      if (this.authenticated) {
        this.go();
      }
    },
    methods: {
      ...mapActions('auth', ['reset', 'signInWithEmailAndPassword']),
      ...mapActions('auth', ['reset', 'createUserWithEmailAndPassword']),
      async signUp () {
        try {
          if (this.$v.$invalid) {
            this.$v.$touch();
            return;
          }
          const email = this.email;
          const password = this.password;
          const result = await this.createUserWithEmailAndPassword({email, password});

          await this.$db().collection('users').doc(result.user.uid).set({
            givenName: this.givenName,
            familyName: this.familyName,
            email: this.email,
            status: 'active',
            company: this.company
          }, {merge: true});

          this.$router.push('dashboard');
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
</script>
